import React from "react";
import "styles/pageloader.css"; // Assuming you saved the CSS code in a file named PageLoader.css

interface CartPageLoader {
  isLoading?: boolean;
}

const CartPageLoader: React.FC<CartPageLoader> = ({ isLoading = true }) => {
  return (
    <>
      {isLoading && (
        <div id="CssLoader">
          <div className="exactCenter">
            <div className="spinftw" style={{top:'38%',left:'52%'}}>
                </div>
                <div className="mt-20 mr-43" style={{fontFamily: 'Poppins, sans-serif'}}>
                <h1>
                Your order is being submitted.<br></br>
                </h1>
                <h1>
                Please<b> do not close this tab </b>until the confirmation page appears.
                </h1>
                </div>
             
          </div>
        </div>
      )}
    </>
  );
};

export default CartPageLoader;
