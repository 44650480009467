import Label from "components/Label/Label";
import React, { ChangeEvent, FC, useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet-async";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import axiosInstance from "api/AxiosInstance";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import PhoneNumberMask from "shared/PhoneNumberMask/PhoneNumberMask";
// import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import HeadingText1 from "components/HeadingText/HeadingText1";

const EditShippingAddress = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // Form data code
  const [formData, setFormData] = useState({
    id: 0,
    companyName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    alternativePhoneNumber: "",
    emailAddress: "",
    addressField1: "",
    addressField2: "",
    city: "",
    province: "",
    country: "",
    postalCode: "",
    userId: "",
    isActive: "",
  });
  const [errors, setErrors] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    addressField1: "",
    city: "",
    postalCode: "",
    province: "",
    country: "",
  });

  const [address, setAddress] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [postcode, setPostcode] = useState<any>("");
  const [province, setProvince] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [alternativePhoneNumber, setalternativePhoneNumber] = useState<any>("");
  const user = useAppSelector((state: RootState) => state.auth);

  const { redirect } = useParams<{ redirect: string }>();
 
  const handleSelect = async (selectedAddress: any) => {
   // setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const addressComponents = results[0].address_components;
      let country = "";
      let city = "";
      let postcode = "";
      let province = "";
      let address = "";

      for (const component of addressComponents) {
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          postcode = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          province = component.long_name;
        }
        if (component.types.includes("street_number")) {
          address = component.long_name;
        }
        if (component.types.includes("route")) {
          address = address + " " + component.long_name;
        }
      }
      setCountry(country);
      setCity(city);
      setPostcode(postcode);
      setProvince(province);
      setAddress(address);
      errors.addressField1 = "";
      errors.city = "";
      errors.postalCode = "";
      errors.province = "";
      errors.country = "";
    } catch (error) {
      console.error("Error fetching address data", error);
    }
  };

  const handleChange = (address: any) => {
    setAddress(address);
    // if (!address) {
    //   setErrors({ ...errors, addressField1: "Address Line 1 is required." });
    // } else {
    //   setErrors({ ...errors, addressField1: "" });
    // }
  };

  const fetchData = async () => {
    const response = await axiosInstance
      .get(`/ShippingAddress/${id}`)
      .then((response) => {
        // Update the state with fetched data
        setFormData(response.data);
        setCountry(response.data.country);
        setCity(response.data.city);
        setPostcode(response.data.postalCode);
        setProvince(response.data.province);
        setAddress(response.data.addressField1);
        setPhoneNumber(response.data.phoneNumber);
        setalternativePhoneNumber(response.data.alternativePhoneNumber);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
   // let errorMessage = "";

    switch (name) {
      // case "companyName":
      //   errorMessage = value.trim() === "" ? "Company Name is required." : "";
      //   break;
      // case "firstName":
      //   errorMessage =
      //     value.trim() === "" ? "Contact First Name is required." : "";
      //   break;
      // case "lastName":
      //   errorMessage =
      //     value.trim() === "" ? "Contact Last Name is required." : "";
      //   break;
      // case "phoneNumber":
      //   errorMessage = value.trim() === "" ? "Phone Number is required." : "";
      //   break;
      // case "emailAddress":
      //   errorMessage =
      //     value.trim() === ""
      //       ? "Email Address is required."
      //       : !/^\S+@\S+\.\S+$/.test(value)
      //       ? "Invalid email address."
      //       : "";
      //   break;
      // case "addressField1":
      //   errorMessage =
      //     value.trim() === "" ? "Address Field 1 is required." : "";
      //   break;
      case "city":
      //  errorMessage = value.trim() === "" ? "City is required." : "";
        setCity(value);
        break;
      case "postalCode":
       // errorMessage = value.trim() === "" ? "Postal Code is required." : "";
        setPostcode(value);
        break;
      case "province":
      //  errorMessage = value.trim() === "" ? "Province is required." : "";
        setProvince(value);
        break;
      case "country":
      //  errorMessage = value.trim() === "" ? "Country is required." : "";
        setCountry(value);
        break;
      default:
        break;
    }

    setFormData({ ...formData, [name]: value });
   // setErrors({ ...errors, [name]: errorMessage });
  };

  const validateForm = () => {
    const newErrors: any = {};
    const scrollToField = (fieldId: string) => {
      const targetDiv = document.getElementById(fieldId);
      if (targetDiv) {
        const divTopPosition = targetDiv.getBoundingClientRect().top + window.scrollY;
        const offset = 50; // Adjust this based on your header height or other layout needs
        window.scrollTo({
          top: divTopPosition - offset,
          behavior: "smooth",
        });
      }
    };
  
    // Check for errors one by one and scroll to the first invalid field
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company Name is required.";
      scrollToField("scroll_div_company");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.firstName.trim()) {
      newErrors.firstName = "Contact First Name is required.";
      scrollToField("scroll_div_firstName");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Contact Last Name is required.";
      scrollToField("scroll_div_lastName");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = "Contact Phone Number is required.";
      scrollToField("scroll_div_phoneNumber");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.emailAddress.trim()) {
      newErrors.emailAddress = "Contact Email Address is required.";
      scrollToField("scroll_div_emailAddress");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.addressField1.trim()) {
      newErrors.addressField1 = "Address Line 1 is required.";
      scrollToField("scroll_div_addressField1");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.city.trim()) {
      newErrors.city = "City is required.";
      scrollToField("scroll_div_city");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.province.trim()) {
      newErrors.province = "Province is required.";
      scrollToField("scroll_div_province");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.country.trim()) {
      newErrors.country = "Country is required.";
      scrollToField("scroll_div_country");
      setErrors(newErrors);
      return true;
    }
  
    if (!formData.postalCode.trim()) {
      newErrors.postalCode = "Postal Code is required.";
      scrollToField("scroll_div_postalCode");
      setErrors(newErrors);
      return true;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length !== 0 ? true : false;
  };

  const handleFormSubmit = async (data: any) => {
    formData.city = city;
    formData.country = country;
    formData.postalCode = postcode;
    formData.province = province;
    formData.addressField1 = address;
    formData.phoneNumber = phoneNumber;
    formData.alternativePhoneNumber = alternativePhoneNumber;
    //If there are validation errors, return
    var isValid = validateForm();
    console.log("=error", Object.keys(errors).length);
    if (isValid) {
      console.log("errors", errors);
      return;
    } else {
      try {
        // Process or save the form data in the parent component
        console.log("Form data received in parent:", data);
        // data.userId = user.userData?.userId;
        data.isActive = true;
        data.id = id;
        setFormData(data); // Example: Setting the form data in state
        const response = await axiosInstance.post(`/ShippingAddress`, data);
        console.log("Response:", response.data);
        if(redirect === "checkout"){
        navigate("/Cart/checkout-order");
        }else{
          navigate("/shipping-address");
        }
      } catch (error) {
        console.error("API Error:", error);
        // Handle error, show an error message, etc.
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Call the onSubmit function from props with the form data
    handleFormSubmit(formData);
  };

  const handlePhoneNumberChange = (value: string) => {
    let errorMessage = "";
    const name = "phoneNumber";
    // if (value === "(   )    -    " || value === "") {
    //   errorMessage = "Phone Number is required.";
    // } else {
    //   errorMessage = "";
    // }
    setPhoneNumber(value);
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handlealternatPhoneNumberChange = (value: string) => {
    setalternativePhoneNumber(value);
  };

  return (
    <div>
      <Helmet>
        <title>Edit Shipping Address | CSC LED</title>
      </Helmet>
      <main className="container py-16 lg:pb-28 lg:pt-20 pt-8">
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <div className="mb-10">
            <HeadingText1 className="font-poppins">
              Edit Shipping Address
            </HeadingText1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex-grow mt-10 md:mt-0 max-w-3xl space-y-6">
              <div id="scroll_div_company">
                <Label>Company Name</Label>
                <Input
                  className="mt-1.5"
                  type="text"
                  style={{
                    border:
                      errors.companyName && errors.companyName !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                  name="companyName"
                  value={formData.companyName || ""}
                  onChange={handleInputChange}
                />
                {errors.companyName && (
                  <span style={{ color: "#fd397a" }}>{errors.companyName}</span>
                )}
              </div>

              <div id="scroll_div_firstName">
                <Label>Contact First Name</Label>
                <Input
                  className="mt-1.5"
                  name="firstName"
                  value={formData.firstName || ""}
                  style={{
                    border:
                      errors.firstName && errors.firstName !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                  onChange={handleInputChange}
                  type="text"
                />
                {errors.firstName && (
                  <span style={{ color: "#fd397a" }}>{errors.firstName}</span>
                )}
              </div>

              <div id="scroll_div_lastName">
                <Label>Contact Last Name</Label>
                <Input
                  className="mt-1.5"
                  type="text"
                  name="lastName"
                  style={{
                    border:
                      errors.lastName && errors.lastName !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                  value={formData.lastName || ""}
                  onChange={handleInputChange}
                />
                {errors.lastName && (
                  <span style={{ color: "#fd397a" }}>{errors.lastName}</span>
                )}
              </div>

              <div id="scroll_div_phoneNumber">
                <Label>Contact Phone Number</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-phone-volume"></i>
                  </span>
                  <span
                    style={{
                      border:
                        errors.phoneNumber && errors.phoneNumber !== ""
                          ? "1px solid #fd397a"
                          : "",
                    }}
                    className="PhoneMaskingcss block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 !rounded-l-none"
                  >
                    <PhoneNumberMask
                      onChange={handlePhoneNumberChange}
                      className={`border ${
                        errors.phoneNumber ? "#fd397a" : ""
                      }`}
                      value={phoneNumber || ""}
                      name="phoneNumber"
                    />
                  </span>
                </div>
                {errors.phoneNumber && (
                  <span style={{ color: "#fd397a" }}>{errors.phoneNumber}</span>
                )}
              </div>

              <div>
                <Label>Contact Alternative Phone Number</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-phone-volume"></i>
                  </span>
                  <span className="PhoneMaskingcss block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 !rounded-l-none">
                    <PhoneNumberMask
                      value={alternativePhoneNumber || ""}
                      onChange={handlealternatPhoneNumberChange}
                      className=""
                      name="alternativePhoneNumber"
                    />
                  </span>
                </div>
              </div>

              {/* ---- */}

              {/* ---- */}
              <div id="scroll_div_emailAddress">
                <Label>Contact Email Address</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-2xl las la-envelope"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    type="text"
                    name="emailAddress"
                    style={{
                      border:
                        errors.emailAddress && errors.emailAddress !== ""
                          ? "1px solid #fd397a"
                          : "",
                    }}
                    value={formData.emailAddress || ""}
                    onChange={handleInputChange}
                  />
                </div>
                {errors.emailAddress && (
                  <span style={{ color: "#fd397a" }}>
                    {errors.emailAddress}
                  </span>
                )}
              </div>

              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div id="scroll_div_addressField1">
                    <Label>Address Line 1</Label>
                    <Input
                      name="addressField1"
                      style={{
                        border:
                          errors.addressField1 && errors.addressField1 !== ""
                            ? "1px solid #fd397a"
                            : "",
                      }}
                      {...getInputProps()}
                    />
                    {errors.addressField1 && (
                      <span style={{ color: "#fd397a" }}>
                        {errors.addressField1}
                      </span>
                    )}
                    <div>
                      {loading ? <div>Loading...</div> : null}
                      <div className="">
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#e9f1f9"
                              : "#f8fafc",
                          };

                          return (
                            <div
                              className="p-2 px-4 first:rounded-t-sm last:rounded-b-sm overflow-hidden text-gray-700 text-base transition-all cursor-pointer"
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                              key={suggestion.placeId}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <div>
                <Label>Address Line 2</Label>
                <Input
                  className="mt-1.5"
                  type="text"
                  name="addressField2"
                  value={formData.addressField2 || ""}
                  onChange={handleInputChange}
                />
              </div>

              <div id="scroll_div_city">
                <Label>City</Label>
                <Input
                  type="text"
                  value={city || ""}
                  className="mt-1.5"
                  onChange={handleInputChange}
                  name="city"
                  style={{
                    border:
                      errors.city && errors.city !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                />
                {errors.city && (
                  <span style={{ color: "#fd397a" }}>{errors.city}</span>
                )}
              </div>
              <div id="scroll_div_province">
                <Label>Province</Label>
                <Input
                  type="text"
                  className="mt-1.5"
                  name="province"
                  value={province || ""}
                  onChange={handleInputChange}
                  style={{
                    border:
                      errors.province && errors.province !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                />
                {errors.province && (
                  <span style={{ color: "#fd397a" }}>{errors.province}</span>
                )}
              </div>

              <div id="scroll_div_country">
                <Label>Country</Label>
                <Input
                  type="text"
                  className="mt-1.5"
                  name="country"
                  value={country || ""}
                  onChange={handleInputChange}
                  style={{
                    border:
                      errors.country && errors.country !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                />
                {errors.country && (
                  <span style={{ color: "#fd397a" }}>{errors.country}</span>
                )}
              </div>
              <div id="scroll_div_postalCode">
                <Label>Postal Code</Label>
                <Input
                  className="mt-1.5"
                  type="text"
                  name="postalCode"
                  value={postcode || ""}
                  onChange={handleInputChange}
                  style={{
                    border:
                      errors.postalCode && errors.postalCode !== ""
                        ? "1px solid #fd397a"
                        : "",
                  }}
                />
                {errors.postalCode && (
                  <span style={{ color: "#fd397a" }}>{errors.postalCode}</span>
                )}
              </div>

              <div className="pt-2">
                <ButtonPrimary type="submit">Save Changes</ButtonPrimary>
                <Link to="/shipping-address">
                  <ButtonSecondary className="ml-2" type="button">
                    Cancel
                  </ButtonSecondary>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default EditShippingAddress;
