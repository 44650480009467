import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { Provider } from "react-redux";
import { store } from "store/store";
import axiosInstance from "api/AxiosInstance";
import { ThemeProvider, useTheme } from "hooks/useTheme";
import { UserStateProvider } from "hooks/useUserState";
library.add(fab, fas, far);

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Home | CSC LED</title>
        {/**Do not allow google bot to crawl this website for now */}
        {process.env.REACT_APP_ENV === "development" ||
          (process.env.REACT_APP_ENV === "staging" && (
            <>
              <meta name="robots" content="noindex, nofollow" />
              <meta name="googlebot" content="noindex" />
            </>
          ))}
      </Helmet>
      <Provider store={store}>
        <ThemeProvider>
          <UserStateProvider>
            <MainApp />
          </UserStateProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
}

const MainApp = () => {
  const { setAssignTheme } = useTheme();

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/Home/getSeasonalTheme")
      .then((response) => {
        toggleTheme(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleTheme = (response: any) => {
    let theme = "light"; //Default theme
    if (response.data.data.isseasonalThemeEnable) {
      theme = "halloween";
    }
    setAssignTheme(theme);
  };

  return (
    <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200 halloween:bg-orange-400 halloween:text-black">
      <MyRouter />
    </div>
  );
};

export default App;
