import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import LikeButton from "components/LikeButton";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import NcImage from "shared/NcImage/NcImage";
import { BaseProp } from "entities/BaseProp";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import ModalPhotos from "containers/ProductDetailPage/ModalPhotos";
import { Lens } from "components/aceternityUI/lens";

//const prodImage = [detail1JPG, detail2JPG, detail3JPG];

interface TransactionSliderProps extends BaseProp {
  prodImage: string[];
  status?: string;
}

const TransactionSlider: React.FC<TransactionSliderProps> = ({
  className = "",
  prodImage,
  status,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const handleOpenModal = (index: number) => {
    setIsOpenGallery(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpenGallery(false);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % prodImage.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + prodImage.length) % prodImage.length
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-white dark:text-white";
    if (status === "Clearance") {
      return (
        <div className={`${CLASSES} bg-red-600`}>
          <CheckBadgeIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">CLEARANCE</span>
        </div>
      );
    }
    if (status === "End of Life") {
      return (
        <div className={`${CLASSES} bg-cyan-500`}>
          <i className="fi fi-rr-clock-three w-3.5 h-3.5"></i>
          <span className="ml-1 leading-none">{`WHILE SUPPLIES LAST`}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`nc-TransactionSlider relative w-full ${className}`}>
      {/**Header main image */}
      <Lens>
        <div className="aspect-w-16 aspect-h-16 overflow-hidden rounded-2xl ">
          <NcImage
            src={prodImage[currentIndex]}
            className="w-full h-full object-contain transition-transform duration-500 ease-in-out lg:cursor-pointer"
            alt={`product detail ${currentIndex}`}
            onClick={() => handleOpenModal(currentIndex)}
          />
        </div>
      </Lens>
      {renderStatus()}
      {/* <LikeButton className="absolute right-3 top-3 " liked={false} /> */}

      <div className="grid grid-cols-3 gap-2.5 mt-2.5">
        {prodImage.map((src, index) => (
          <div key={index} onClick={() => setCurrentIndex(index)}>
            <NcImage
              containerClassName="w-full h-24 sm:h-40 group cursor-pointer"
              className={`object-contain w-full h-full group-hover:scale-110 transform transition-transform duration-300 rounded-2xl`}
              src={src}
            />
          </div>
        ))}
      </div>

      {prodImage.length > 1 && (
        <>
          <Prev
            className="absolute left-1 sm:left-5 top-2/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
            btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
            svgSize="w-6 h-6"
            onClickPrev={prevSlide}
          />
          <Next
            className="absolute right-1 sm:right-5 top-2/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
            btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
            svgSize="w-6 h-6"
            onClickNext={nextSlide}
          />
        </>
      )}

      {/* MODAL PHOTOS */}
      <ModalPhotos
        imgs={prodImage}
        isOpen={isOpenGallery}
        onClose={handleCloseModal}
        initFocus={openFocusIndex}
        uniqueClassName="nc-ProductDetailPage2__modalPhotos"
      />
    </div>
  );
};

export default TransactionSlider;
