import {
  BuildingOfficeIcon,
  CheckIcon,
  TruckIcon,
} from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { BaseProp } from "entities/BaseProp";
import { LocationStock, ProductDetailsDTO } from "entities/ProductDetailsDTO";
import CurrencyFormatter from "components/CurrencyFormatter";
import ContactUsRawHTML from "containers/ContactUs/ContactUsRawHTML";
import NcLink from "shared/NcLink/NcLink";
import { config } from "utils/Constance";
import { NavLink, useLocation } from "react-router-dom";
import { getUserLoginData } from "utils/auth";
import ButtonCSCLed from "shared/Button/ButtonCSCLed";
import Rating from "components/Rating";
import ModalNotifyToCart from "./ModalNotifyToCart";
import axiosInstance from "api/AxiosInstance";
import handleScrollToEl from "utils/HandleScrollToEl";
import ModalErrorNotify from "./ModalErrorNotify";
import { useAppDispatch } from "../../store/hooks";
import { getCartData } from "../../store/slices/cartSlice";
import { getCartListData } from "../../store/slices/cartDetailListSlice";
import { getCartIndexListData } from "../../store/slices/cartIndexListSlice";
import { useUserState } from "hooks/useUserState";
import Subscribe_image from "images/product-detail/Subscribe_image.png";
import Subscribe_image_mobile from "images/product-detail/Subscribe_image_mobile.png";
import NcImage from "shared/NcImage/NcImage";

interface StickySideBarProps extends BaseProp {
  data: ProductDetailsDTO;
  isShortDataList?: boolean;
}

const StickySideBar: FC<StickySideBarProps> = ({
  className = "",
  data,
  isShortDataList,
}) => {
  const userData = getUserLoginData();
  const _userStateConfig = useUserState();

  const [qualitySelected, setQualitySelected] = useState(1);
  const [isModalAddToCart, setIsModalAddToCart] = useState(false);
  const [isModalWarningMessage, setIsModalWarningMessage] = useState(false);
  const [addToCartMessage, setAddToCartMessage] = useState(undefined);
  const [isCartButtonDisable, setIsCartButtonDisable] = useState(false);
  //
  const location = useLocation();
  const onCartOpen = () => setIsModalAddToCart(true);
  const onCartClose = () => setIsModalAddToCart(false);
  const dispatch = useAppDispatch();

  const addToCart = async () => {
    if (isNaN(qualitySelected) || qualitySelected <= 0) {
      setIsModalWarningMessage(true);
      return;
    }
    setIsCartButtonDisable(true);
    var obj = {
      productId: data.inventoryId,
      qty: qualitySelected,
      productValue: data.userPrice,
    };
    const response = await axiosInstance
      .post("/Cart/saveProductToCart", obj)
      .then((response) => {
        setAddToCartMessage(response.data.data);
        onCartOpen();
        dispatch(getCartData());
        dispatch(getCartListData());
        dispatch(getCartIndexListData());
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setIsCartButtonDisable(false);
  };

  const renderAddToCart = () => {
    return (
      <div className="flex space-x-3.5">
        <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
          <NcInputNumber
            defaultValue={qualitySelected}
            onChange={(e) => {
              setQualitySelected(isNaN(e) ? 0 : e);
            }}
          />
        </div>
        <ButtonCSCLed
          loading={isCartButtonDisable}
          disabled={isCartButtonDisable}
          className="flex-1 flex-shrink-0"
          onClick={() => addToCart()}
        >
          <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
          <span className="ml-3 lg:text-xl">Add to cart</span>
        </ButtonCSCLed>
      </div>
    );
  };

  const renderTradePrice = (
    title: string,
    price: number,
    additionalMsg: string = ""
  ) => {
    return (
      <div
        className={`nc-WidgetPosts rounded-xl overflow-hidden bg-neutral-100 dark:bg-neutral-800 text-center`}
        data-nc-id="WidgetPosts"
      >
        <div
          className={`nc-WidgetHeading1 p-3 border-b border-neutral-200 dark:border-neutral-700 ${className}`}
          data-nc-id="WidgetHeading1"
        >
          <h2 className="text-2xl text-neutral-900 dark:text-neutral-100 font-semibold">
            {title}
          </h2>
        </div>
        <div
          className={`nc-Card3Small p-4 xl:px-5 xl:py-6`}
          data-nc-id="Card3Small"
        >
          <h2 className="nc-card-title text-4xl font-semibold text-neutral-900 dark:text-neutral-100">
            <CurrencyFormatter value={price} />
          </h2>
          {additionalMsg && (
            <div className="text-sm sm:text-base font-semibold mt-2">
              {additionalMsg}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderLocationStock = () => {
    if (data.locationStockList && data.isDisplayStock)
      return (
        <>
          <div className="font-semibold">
            Live stock updates specific to each branch
          </div>
          {data.locationStockList.map((item, index) =>
            renderLocationQty(item, index)
          )}
        </>
      );

    if (!data.isDisplayStock && data.specialStockHeader)
      return (
        <div className="space-y-2">
          <p className="font-semibold">
            {"Short Lead Time Required (Depending on Qty)"}
          </p>
          <ContactUsRawHTML
            rawHtml={
              "<p>Lead time for installation will be required. CSC LED install only due to drilling necessary for photocell receptacle installation.&nbsp;<br></p><p><br></p><p>If you have any questions, contact info@csc-led.com</p>"
            }
          />
        </div>
      );
  };

  const renderLoginUser = () => {
    return (
      <>
        {/*  ---------- Trade Price */}
        <div
          className={`grid gap-3 grid-cols-1 ${
            data.isSuggestedPriceTier ? "sm:grid-cols-1" : "sm:grid-cols-2"
          }`}
        >
          {!data.isSuggestedPriceTier &&
            renderTradePrice("Your Price", data.userPrice)}
          {renderTradePrice(
            `Suggested Trade Price`,
            data.suggestedTradePrice,
            data.isSuggestedPriceTier
              ? "Contact your local distributor for exact pricing"
              : ""
          )}
        </div>
        {/*  ---------- QTY AND ADD TO CART BUTTON */}
        {!_userStateConfig?.isHideCart && renderAddToCart()}
        {/*  ---------- Free Shipping */}
        <div
          className={`flex flex-col py-2 px-5 rounded-2xl bg-green-50 dark:bg-opacity-90`}
        >
          <div className="flex mt-2.5">
            <div className="mr-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 14H13C14.1 14 15 13.1 15 12V2H6C4.5 2 3.19001 2.82999 2.51001 4.04999"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 17C2 18.66 3.34 20 5 20H6C6 18.9 6.9 18 8 18C9.1 18 10 18.9 10 20H14C14 18.9 14.9 18 16 18C17.1 18 18 18.9 18 20H19C20.66 20 22 18.66 22 17V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L18.58 6.01001C18.22 5.39001 17.56 5 16.84 5H15V12C15 13.1 14.1 14 13 14H12"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 12V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L22 12Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 8H8"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 11H6"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 14H4"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <NcLink
              to={`${config.url.ERP_Domain}Uploads/HomePageBanner/Free-Shipping-Extended-Details.png`}
              target="_blank"
            >
              <p className="font-semibold text-slate-900">
                Free Nationwide Shipping* on orders over $250 before taxes.
              </p>
              <p className="text-slate-500 mt-0.5 text-sm">{"Details >"}</p>
            </NcLink>
          </div>
        </div>
        {/*  ---------- Location wise Qty */}
        {renderLocationStock()}
      </>
    );
  };

  const renderLoginButton = () => {
    return (
      <div className="space-y-5">
        <NavLink to={`/login`} state={{ from: location.pathname }}>
          <ButtonPrimary className="w-full">
            <span>Log in to view inventory availability and pricing</span>
          </ButtonPrimary>
        </NavLink>
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          {`Don't have an account? `}
          <NcLink className="text-green-600" to="/signup">
            Sign up here.
          </NcLink>
        </span>
        <NcImage
          containerClassName="hidden lg:block"
          className="object-fill"
          src={Subscribe_image}
        />
        <NcImage
          containerClassName="lg:hidden px-7"
          src={Subscribe_image_mobile}
        />
      </div>
    );
  };

  const renderProductRating = () => {
    return (
      <div
        className={`flex flex-col text-sm font-medium sm:items-end ${
          data.productRatings.productStars > 0 ? "" : "hidden"
        }`}
      >
        <Rating
          count={data.productRatings.productStars}
          value={data.productRatings.productStars}
          size={22}
        />
        <span
          onClick={() => handleScrollToEl("nc-ProductReview")}
          className="text-slate-700 dark:text-slate-400 underline cursor-pointer"
        >
          {data.productRatings.productReviewsList.length}{" "}
          {data.productRatings.productReviewsList.length > 1
            ? "reviews"
            : "review"}
        </span>
      </div>
    );
  };

  const renderSectionSidebar = () => {
    return (
      <>
        <div
          className={`product_detail_page custom-scrollbar lg:shadow-lg lg:h-[82vh] lg:overflow-y-auto border rounded-lg halloween:border-white`}
        >
          <div className="space-y-7 lg:space-y-8">
            {/* PRICE */}
            <div className="">
              {/* ---------- 1 HEADING ----------  */}
              <div className="flex items-center justify-between space-x-5">
                <h2 className="text-csccolor text-xl md:text-3xl font-semibold">
                  {data.productName}
                </h2>
                {data.productRatings && (
                  <div className="hidden sm:block">{renderProductRating()}</div>
                )}
              </div>
            </div>
            <div className="!mt-2">
              <h2
                className={`nc-ProductCard__title text-lg sm:text-xl font-semibold transition-colors`}
              >
                {data.magnetoName}
              </h2>
              <p
                className={`text-base sm:text-lg text-slate-500 dark:text-slate-400 halloween:text-black mt-1 `}
              >
                {data.shortDescription}
              </p>
              {data.productRatings && (
                <div className="mt-2 sm:hidden">
                  {data.productRatings && renderProductRating()}
                </div>
              )}
            </div>
            {userData ? renderLoginUser() : renderLoginButton()}
          </div>
        </div>
      </>
    );
  };

  const renderLocationQty = (data: LocationStock, index: number) => {
    return (
      <div
        key={index}
        className="border border-slate-200 dark:border-slate-700 halloween:border-white rounded-xl cursor-auto"
      >
        <div className="flex flex-col px-6 py-3 ">
          <h3 className=" text-slate-700 dark:text-slate-300 flex ">
            <span className="uppercase tracking-tight">
              {data.locationName}
            </span>
          </h3>
          {data.stockDetailList.map((item, index) => (
            <div
              key={index}
              className="flex flex-row mt-3 font-semibold text-sm"
            >
              <div className="flex w-1/2 sm:w-56">
                {item.stockType === "In Stock" ? (
                  <BuildingOfficeIcon
                    className="w-5 h-5 opacity-70 mr-3 text-emerald-500"
                    aria-hidden="true"
                  />
                ) : (
                  <TruckIcon
                    className="w-5 h-5 opacity-70 mr-3 text-emerald-500"
                    aria-hidden="true"
                  />
                )}
                <span>{item.stockType}:</span>
              </div>
              <div className="flex shrink-0 flex-col sm:flex-row">
                <div className="sm:w-20">{item.qty}</div>
                <div className="">{item.arrivalDate}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const onShortDataListAddtoCart = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll
    });
    setIsModalAddToCart(false); // Close the modal
    dispatch(getCartListData());
    dispatch(getCartData());
    dispatch(getCartIndexListData());
  };

  return (
    <>
      {data && renderSectionSidebar()}
      <ModalNotifyToCart
        isModalOpen={isModalAddToCart}
        onCloseModal={onCartClose}
        modalBody={addToCartMessage}
        isShortDataList={isShortDataList}
        onCloseIsShortListAdded={onShortDataListAddtoCart}
      />
      <ModalErrorNotify
        isModalOpen={isModalWarningMessage}
        onCloseModal={() => setIsModalWarningMessage(false)}
        titleMsg="Human, your selected quantity is either blank or zero. As result, I can't add this this product to your cart."
        confirmButtonText="I understand computer"
      />
    </>
  );
};

export default StickySideBar;
